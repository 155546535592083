@tailwind base;
@tailwind components;
@tailwind utilities;
@import "scroll.css";

@layer base {
  /* ↓↓↓ Applys Tailwind utility classes to establish some baseline global styles. */
  * { @apply box-border; }

  body, p, ol, ul, td { @apply font-sans leading-5 m-0 text-gray-800; }

  a { @apply text-gray-800; }

  a:hover { @apply underline; }

  label { @apply block; }

  button { @apply m-0; }

  ul { @apply m-0 p-0; }
  /* ꜛꜛꜛ End baseline global styles */

  h1 { @apply text-3xl }

  h2 { @apply text-2xl }

  h3 { @apply text-xl }
}


@layer components {
  /* Add any necessary component level semantic classes here... for exmaple: */
  .btn {
    @apply text-sm py-2 px-8 rounded-md text-white bg-black cursor-pointer whitespace-nowrap hover:bg-gray-700 no-underline font-semibold disabled:bg-opacity-30 disabled:hover:bg-opacity-30 disabled:cursor-not-allowed;
  }
  .btn-main { @apply px-4 py-1 border border-black disabled:border-slate-400 disabled:hover:bg-transparent text-black text-sm rounded whitespace-nowrap hover:bg-green-400 hover:border-green-400 hover:no-underline}
  .btn-success { @apply btn bg-green-600 hover:bg-green-500}
  .btn-danger { @apply btn bg-red-800 hover:bg-red-500}
  .btn-warning { @apply btn bg-yellow-600 hover:bg-yellow-500}
  .btn-second {
    @apply text-sm p-2 px-8 rounded-md text-gray-800 bg-white border border-gray-800 cursor-pointer no-underline font-semibold hover:no-underline hover:text-gray-500 hover:border-gray-500 hover:bg-white;
  }
}


@layer utilities {
  /* Add new utility classes here,,, for example: */
  /* .h-half-screen {
    height: 50vh;
  } */
}
