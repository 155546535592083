.scroll-container {
    position: relative;
    width: 100%;
    height: fit-content;
}

.scroll-content {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: auto;
    transform: rotateX(180deg);
    cursor: grab;
}

.scroll-container:active {
    cursor: grabbing;
}

.scroll-content .content {
    display: flex;
    flex-direction: row;
    transform: rotateX(180deg);
}

.hidden-scroll {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hidden-scroll::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.w-fit-content {
    width: fit-content;
}

.button-imitation {
    cursor: pointer;
}
